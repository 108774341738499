import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-2" }
const _hoisted_2 = {
  key: 0,
  class: "material-icons-outlined text-muted",
  style: {"margin-bottom":"-1px","font-size":"22px !important"}
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-base" }
const _hoisted_5 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Message = _resolveComponent("Message")!
  const _component_MessageEditor = _resolveComponent("MessageEditor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.comments.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "question_answer"))
        : _createCommentVNode("", true),
      (_ctx.comments.length > 0)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.comments.length) + " " + _toDisplayString(_ctx.comments.length === 1
						? _ctx.$t("course_tree.node_comment_title")
						: _ctx.$t("course_tree.node_comments_title")), 1))
        : _createCommentVNode("", true),
      (_ctx.shownComments.length < _ctx.comments.length || _ctx.expanded)
        ? (_openBlock(), _createBlock(_component_Btn, {
            key: 2,
            style: {"margin-bottom":"-1px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded)),
            size: 'xs',
            variant: 'primary-borderless'
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.expanded ? _ctx.$t("misc.show_recent") : _ctx.$t("misc.show_all")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shownComments, (comment) => {
        return (_openBlock(), _createBlock(_component_Message, {
          key: comment.id,
          user: comment.user,
          canDelete: _ctx.canDelete(comment),
          message: comment.comment,
          class: _normalizeClass(comment.created),
          onDeleteMessage: ($event: any) => (_ctx.onDelete(comment.id))
        }, null, 8, ["user", "canDelete", "message", "class", "onDeleteMessage"]))
      }), 128))
    ]),
    _createVNode(_component_MessageEditor, {
      posting: _ctx.postingComment,
      onSendMessage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAddComment($event.message, $event.resolveFn, $event.rejectFn)))
    }, null, 8, ["posting"])
  ]))
}