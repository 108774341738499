
import { getTranslatedString as _ } from "@/i18n";
import { courseIdMixin, coursePrivilegeMixin, loadingMixin } from "@/mixins";
import { CoursePrivilege, getBlankCourseTreeNodeComment, NodeComment } from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { getCurrentUserId, setErrorNotification } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import Message from "../messaging/Message.vue";
import MessageEditor from "../messaging/MessageEditor.vue";
import Avatar from "../ui/Avatar.vue";
import Btn from "../ui/Btn.vue";
import TextInput from "../ui/TextInput.vue";
import Timestamp from "../ui/Timestamp.vue";
export default defineComponent({
	name: "CourseTreeNodeCommentSection",
	mixins: [courseIdMixin, loadingMixin, coursePrivilegeMixin],
	props: {
		nodeId: {
			type: String,
			required: true,
		},
		loadingComments: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			postingComment: false,
			expanded: false,
		};
	},
	methods: {
		async onAddComment(message: string, resolveFn: () => void, rejectFn: () => void) {
			this.postingComment = true;
			try {
				await this.mainStore.createCourseTreeNodeComment({
					courseId: this.courseId,
					nodeId: this.nodeId,
					comment: getBlankCourseTreeNodeComment(message),
				});
				resolveFn();
			} catch (e) {
				setErrorNotification(e);
				rejectFn();
			} finally {
				this.postingComment = false;
			}
		},
		async onDelete(commentId: string) {
			if (!confirm(_("course_tree.confirm_delete_comment"))) {
				return;
			}
			try {
				await this.mainStore.deleteCourseNodeComment({
					courseId: this.courseId,
					nodeId: this.nodeId,
					commentId,
				});
			} catch (e) {
				setErrorNotification(e);
			}
		},
		canDelete(comment: NodeComment) {
			return (
				this.hasPrivileges([CoursePrivilege.MANAGE_MATERIAL]) ||
				comment.user.id == getCurrentUserId()
			);
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		comments() {
			return this.mainStore.getCommentsByNodeId(this.nodeId);
		},
		shownComments() {
			return this.comments.length <= 2 || this.expanded
				? this.comments
				: this.comments.slice(-2);
		},
	},
	components: { Btn, MessageEditor, Message },
});
